<template>
  <ion-page>
    <ion-header>
      <ion-toolbar>
        <ion-title>表示する児童を選択します</ion-title>
      </ion-toolbar>
    </ion-header>
    <ion-content :fullscreen="true">
        <ion-card v-for="user in users" v-bind:key="user.id">
            <ion-card-header>
              <ion-card-title>{{user.userName}}</ion-card-title>
            </ion-card-header>
            <ion-card-content>
                <ion-button shape="round" @click="choose(user)">選択</ion-button>
            </ion-card-content>
        </ion-card>
    </ion-content>
  </ion-page>
</template>

<script>
import {
  IonPage,
  IonContent,
  IonHeader,
  IonCard,
  IonCardTitle,
  IonCardHeader,
  IonCardContent,
  IonTitle,
  IonToolbar,
  IonButton,
} from '@ionic/vue';
export default  {
  name: 'SelectUser',
  components: {
    IonPage,IonContent,IonHeader,IonCard,IonCardTitle,IonCardHeader,
    IonCardContent,IonTitle,IonToolbar,IonButton
  },
  data(){
    return {
      users: this.$store.state.users, //from vuex
    }
  },
  mounted(){
     console.log(this.$store.state.users);
  },
  methods : {
    async choose(user){
      await this.$store.commit('setActiveUser',user);
      await this.$router.push("/home");
    }
  }
}
</script>